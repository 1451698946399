$(document).on('focus', '.datepicker', function() {
	$(this).datepicker({
		dateFormat: "yy-mm-dd"
	});
});

$(document).ready(function() {
	$('#event_images').on('cocoon:before-insert', function(event, insertedItem) {
	  var count = $(this).find('.nested-fields').length;
	  if( count > 0 ){
	  	alert("You can only add one image to this event.");
	    event.preventDefault();
	  }
	});
});