var filter_version = "1.0";

$(document).ready( function (e) {
	$(".select-all").click( function (e) {
		var action = $(this).data("action");
		var name = $(this).data("target");
		$("fieldset.search_" + name + " input[type=checkbox]").prop("checked", action == "all" ? true : false);
		$(this).text(action == "all" ? "Select None" : "Select All");
		$(this).data("action", action == "all" ? "none" : "all")
		// submitForm();
	});
	
	function submitForm() {
		form = document.querySelector('#listingSearch');
		if (typeof form.requestSubmit === 'undefined') {
			form.dispatchEvent(new Event('submit', {bubbles: true}));
		} else {
			form.requestSubmit();
		}
	}

	// filter towns based on selected region
	$("#search_regions").change( function (e) {
		var region_id = $(this).val();
		if ( region_id == "" ) {
			$("#search_town option").removeAttr("disabled");
		} else {
			$("#search_town option").attr("disabled", "disabled");
			$("#search_town option.region_" + region_id).removeAttr("disabled");
			$("#search_town option[value='']").removeAttr("disabled");					
			if ( $("#search_town option:selected").hasClass("region_" + region_id) ) {
				// noop
			} else {
				$("#search_town").val("");
			}
		}
	});

	$("#listingSearch").submit(function(e) {
		// For filter analytics, loop over every checked section and fire events for each
		fireFilterEvents();

		history.pushState(null, '', $('#listingSearch').attr('action') + '?' + $('#listingSearch input#search_query').serialize() + "&" + $('#listingSearch :input[value!=""][name!="search[seed]"]').serialize());
	});

	$("#narrowSearch").click(function (e) {
		$("#additionalSearchFields").toggleClass("tenf-hidden");
		$(".narrow-search-icon").toggleClass("tenf-hidden");
	});
});

function fireFilterEvents() {
	$('#listingSearch .form-check-input:checked').each(function() {
		let label = $(this).next('.form-check-label').text();

		let parent = $(this).parents('fieldset').first();
		let category = parent.prev('.category-header').find('.text-info').text().trim();
		if (!category) {
			category = parent.prev('.text-info').text().trim();
		}

		fireSingleFilterEvent(label, category, 'listings');
	});

	$('#listingSearch select.form-control option:selected').each(function() {
		let value = $(this).val();

		if (value.length > 0) {
			let label = $(this).text();
			let parent = $(this).parents('select').first();
			let category = parent.find('option:first').text().trim();

			fireSingleFilterEvent(label, category, 'listings');
		}
	});
}

function fireSingleFilterEvent(label, category, location) {
	if ( typeof gtag !== 'undefined' ) {
		gtag('event', 'filter_used', {
		  'filter_name': label,
		  'filter_category': category,
		  'filter_location': location,
		  'filter_version': filter_version,
		});
	}
}